<template>
  <v-footer dark padless>
    <v-card color="grey darken-4 px-4" class="flex" flat tile>
      <v-card-text>
        <v-row justify="space-around">
          <v-col cols="4">
            <v-img src="img/indosur.png" width="80px" />
            <p>Droguería Indosur</p>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon v-text="'mdi-map-marker'" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="'Salta 631, Paraná, Entre Ríos'" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="4">
            <v-card color="grey darken-4" flat>
              <v-card-title v-text="'Teléfonos'" />
              <v-list>
                <v-list-item v-for="(phone, index) in phones" :key="index">
                  <v-list-item-avatar>
                    <v-icon v-text="'mdi-phone'" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="phone" />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col cols="4">
            <v-card color="grey darken-4" flat>
              <v-card-title v-text="'Contacto'" />
              <v-list>
                <v-list-item
                  two-line
                  v-for="(contact, index) in contacts"
                  :key="index"
                >
                  <v-list-item-avatar>
                    <v-icon v-text="'mdi-mail'" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <template v-if="contact.mails">
                      <v-list-item-title
                        v-for="(mail, index) in contact.mails"
                        :key="index"
                        v-text="mail"
                      />
                    </template>
                    <v-list-item-title v-else>
                      <v-list-item-title v-text="contact.mail" />
                    </v-list-item-title>
                    <v-list-item-subtitle v-text="contact.label" />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="py-2 white--text text-center">
        <strong>&copy; Indosur</strong> - {{ new Date().getFullYear() }} - DO
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
  data: () => ({
    phones: [
      "0343-4230700",
      "0343-4220660",
      "0343-4226466",
      "0343-4227767",
      "0343-4226255",
    ],
    contacts: [
      {
        label: "Convenios Obras Sociales",
        mail: "convenios@indosursa.com.ar",
      },
      {
        label: "Administración / Pagos",
        mail: "administracion@indosursa.com.ar",
      },
      {
        label: "Ventas",
        mails: [
          "ventasindosursa@indosursa.com.ar",
          "ventasindosur@indosursa.com.ar",
        ],
      },
    ],
  }),
};
</script>


