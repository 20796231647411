<template>
  <div>
    <v-app-bar color="grey lighten-4" app>
      <v-img
        class="mx-2"
        src="img/indosur.png"
        max-height="100"
        max-width="100"
        contain
      ></v-img>

      <v-btn text color="teal" to="/"> Inicio </v-btn>
      <v-spacer />

      <SessionComponent />
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <FooterComponent />
  </div>
</template>
<script>
import SessionComponent from "../components/shared/SessionComponent.vue";
import FooterComponent from "../components/shared/FooterComponent.vue";

export default {
  data: () => ({}),

  components: {
    SessionComponent,
    FooterComponent,
  },
};
</script>
